import React from "react";
import { View } from '@aws-amplify/ui-react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton, GridToolbarExport, ptBR } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ModalTicketDetails from "../ModalTicketDetails";
import { columns } from "../models";

function CustomToolbar(props) {
    //console.log("CustomToolbar props", props);
    return (
        <GridToolbarContainer sx={{ p: 2 }}>
            <GridToolbarFilterButton sx={{ ml: 1 }} variant="outlined" />
            <GridToolbarExport sx={{ ml: 1 }} variant="outlined" color='secondary' />
        </GridToolbarContainer>
    );
}

function Tasks({ user, userInfo, tickets, loading, handleShowCreate }) {
    const [currentTicket, setCurrentTicket] = React.useState(null);
    const [showDetails, setShowDetails] = React.useState(false);

    columns[0] = {
        headerName: '#',
        field: 'id2',
        type: 'number',
        minWidth: 50,
        flex: 0.2,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (params) => {
            return <IconButton size="small" color="primary" onClick={() => {
                setCurrentTicket({ params });
                setShowDetails(true);
            }}>
                <ZoomInIcon />
            </IconButton>;
        }
    };

    columns[5] = {
        headerName: 'Data Criação',
        field: 'createdDate',
        type: 'dateTime',
        minWidth: 50,
        flex: 0.3,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        valueFormatter: params => params.value !== '' ? new Date(params.value).toLocaleString("pt-BR") : '',
    };

    columns[6] = {
        headerName: 'Data Fechamento',
        field: 'closedDate',
        type: 'dateTime',
        minWidth: 50,
        flex: 0.3,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        valueFormatter: params => params.value !== '' ? new Date(params.value).toLocaleString("pt-BR") : '',
    };

    columns[10] = {
        headerName: 'Anexos',
        field: 'ufTaskWebdavFiles',
        minWidth: 20,
        flex: 0.1,
        headerClassName: 'super-app-theme--header',
        headerAlign: 'right',
        renderCell: (params) => {
            return !!params.value && params.value.length > 0 ? <IconButton size="small"><AttachFileIcon /></IconButton> : <></>;
        }
    };

    const handleCloseDetails = () => setShowDetails(false);

    return (
        <View>
            <ModalTicketDetails {...{ user, showDetails, handleCloseDetails, currentTicket }} />
            <Box
                sx={{
                    '& .super-app-theme--header': {
                        backgroundColor: 'rgba(192, 192, 192, 0.55)',
                        fontWeight: 'bold',
                    },
                }}
            >
                <DataGrid
                    rows={tickets}
                    columns={columns}
                    localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                    loading={loading}
                    autoHeight={true}
                    rowsPerPageOptions={[25, 50, 100]}
                    pageSize={25}
                    resizable={true}
                    pagination
                    disableSelectionOnClick
                    components={{ Toolbar: CustomToolbar }} />
            </Box>
        </View>
    );
}

export default React.memo(Tasks);
