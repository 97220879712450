import * as React from 'react';
import { Amplify, API, Auth, I18n } from "aws-amplify";
import { withAuthenticator, translations } from '@aws-amplify/ui-react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';

import { Header, Footer } from "./SignIn/SignInHeaderFooter";
import { Header as MainHeader } from "./Header/Header";
import Tasks from "./Tasks/Tasks";
import ModalTicketCreate from "./ModalTicketCreate";
import { getTickets, checkAccess } from './queries';
import StartPage from "./StartPage";

import "./App.css";

import awsExports from './aws-exports';
Amplify.configure(awsExports);

I18n.putVocabularies(translations);
I18n.putVocabularies({
  pt: {
    'User does not exist': 'Usuário não existe ou não esta autorizado.',
  },
  ptBr: {
    'User does not exist': 'Usuário não existe ou não esta devidamente autorizado.',
  },
  "pt-Br": {
    'User does not exist': 'Usuário não existe ou não esta autorizado na App.',
  },
  "pt-br": {
    'User does not exist': 'Usuário não existe ou não esta devidamente autorizado na App.',
  },
});
I18n.setLanguage('pt-br');

const myAPI = "interatellportalapi";

const services = {

  async handleSignUp(formData) {
    try {
      let { username, password, attributes } = formData;
      username = username.toLowerCase();
      attributes.email = attributes.email.toLowerCase();

      console.log("handleSignUp attributes.email", attributes.email);
      const response = await API.get(myAPI, "/checkaccess/" + attributes.email);
      console.log("handleSignUp API.get response ", response);
      if (response && response.ID) {
        return Auth.signUp({
          username,
          password,
          attributes,
          autoSignIn: {
            enabled: true,
          },
        });
      } else {
        return Auth.signUp(
          "xxxxxxxxx",
          "xxxxxxxxx",
          "xxxxxxxxx@xxxxxx"
        );
        //throw new Error("Usuário/Senha incorretos ou não autorizados");
      }
    } catch (error) {
      console.log('handleSignUp error:', error);
    }
  },

  async handleSignIn(formData) {
    try {
      let { username, password } = formData;
      username = username.toLowerCase();

      console.log("handleSignIn username", username);
      const response = await API.get(myAPI, "/checkaccess/" + username);
      console.log("handleSignIn API.get response ", response);

      if (response && response.ID) {
        //setUserInfo(response);
        return Auth.signIn({
          username,
          password
        });
      } else {
        return Auth.signIn(
          "xxxxxxxxx",
          "xxxxxxxxx"
        );
      }
    } catch (error) {
      console.log('handleSignIn error:', error);
    }
  },
};

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://elege.me/" target="_blank">
        Elege.me - Distribuidor Gold Bitrix24
      </Link>{' - '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const mdTheme = createTheme();

export function App({ signOut, user }) {
  const [tickets, setTickets] = React.useState([]);
  const [userInfo, setUserInfo] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [showCreate, setShowCreate] = React.useState(false);
  const [showTaskList, setShowTaskList] = React.useState(false);

  const handleCloseCreate = () => setShowCreate(false);
  const handleShowCreate = () => setShowCreate(true);
  const handleShowTaskList = () => setShowTaskList(true);

  const loadTasks = async () => {
    setLoading(true);
    const responseUser = await checkAccess(user);
    console.log("loadTasks responseUser", responseUser);
    setUserInfo(responseUser);
    const response = await getTickets(user, {});
    setTickets(response);
    setLoading(false);
  };

  React.useEffect(() => {
    const load = async () => {
      loadTasks();
    };
    load();
  }, [user]);

  //return (<StartPage />);
  if (!showTaskList) {
    return (
      <>
        <StartPage {...{ handleShowTaskList, handleShowCreate }} />
        {showCreate && (<ModalTicketCreate {...{ user, userInfo, showCreate, handleCloseCreate, loadTasks }} />)}
      </>);
  } else {
    return (
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <MainHeader {...{ signOut, user, handleShowCreate }} />
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
            <Toolbar />
            <Container maxWidth="90%">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <ModalTicketCreate {...{ user, userInfo, showCreate, handleCloseCreate, loadTasks }} />
                    <Tasks {...{ user, tickets, loading, handleShowCreate }} />
                  </Paper>
                </Grid>
              </Grid>
              <Copyright sx={{ pt: 4 }} />
            </Container>
          </Box>
        </Box>
      </ThemeProvider>);
  }

}

export default withAuthenticator(App, {
  components: {
    Header,
    Footer
  },
  services: services
});
