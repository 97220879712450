import { View, Flex, Text, Image, useTheme } from "@aws-amplify/ui-react";

export function Header() {
    const { tokens } = useTheme();

    return (
        <View textAlign="center" backgroundColor="#032756">
            <Image
                alt="logo"
                src="./logo_interatell.png"
                objectPosition="30% 30%"
                objectFit="initial"
                width="50%"
                padding={tokens.space.medium}
            />
        </View>
    );
}

export function Footer() {
    const { tokens } = useTheme();

    return (
        <Flex justifyContent="center" padding={tokens.space.medium}>
            <Text>&copy; All Rights Reserved</Text>
        </Flex>
    );
}
