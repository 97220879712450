import { Storage, API } from "aws-amplify";
import { v4 as uuid } from "uuid";

const myAPI = "interatellportalapi";
const myDomain = "https://interatell.bitrix24.com.br";

export const checkAccess = async (user, info) => {
    try {
        const response = await API.get(myAPI, `/checkaccess/${user.attributes.email}`);
        console.log("checkAccess API.get response ", response);

        if (response !== "No params" && !!response) {
            return response;
        } else {
            return null;
        }
    } catch (err) {
        console.error(err);
    }
};

export const getTickets = async (user, params) => {
    try {
        const response = await API.get(myAPI, `/tickets/${user.attributes.email}`);
        console.log("getTickets API.get response ", response);

        if (response !== "No params") {
            return response;
        } else {
            return [];
        }
    } catch (err) {
        console.error(err);
    }
};

export const createTicket = async (email, params) => {
    console.log("createTicket params ", params);

    if (params.files !== null) {
        const id = uuid();
        const fileName = params.files.name;
        const fileKey = `${id}#${fileName}`;
        console.log("createTicket fileKey ", fileKey);

        const { key } = await Storage.put(fileKey, params.files, {
            contentType: params.files.type
        });
        console.log("createTicket key ", key);
        params.files = key;
    }


    try {
        const myInit = {
            body: params,
            headers: {}
        };
        const response = await API.post(myAPI, `/createticket/${email}`, myInit);
        console.log("createTicket API.get response ", response);

        if (response !== "No params") {
            return response;
        } else {
            return null
        }
    } catch (err) {
        console.error(err);
    }
};

export const getTicketComments = async (email, ticketId) => {
    console.log("getTicketComments email ", email);
    console.log("getTicketComments ticketId ", ticketId);
    try {
        const response = await API.get(myAPI, `/comments/${email}/${ticketId}`);
        console.log("getTicketComments API.get response ", response);

        if (response !== "No params" && response.length > 0) {
            for (let index = 0; index < response.length; index++) {
                const element = response[index];
                if (!!element.ATTACHED_OBJECTS) {
                    console.log("getTicketComments element.ATTACHED_OBJECTS ", element.ATTACHED_OBJECTS);
                    for (const property in element.ATTACHED_OBJECTS) {
                        console.log("getTicketComments property", property);
                        console.log("getTicketComments element.ATTACHED_OBJECTS[property]", element.ATTACHED_OBJECTS[property]);
                        let newName = element.ATTACHED_OBJECTS[property].NAME;
                        if (newName.search("#") > -1) {
                            newName = newName.slice(newName.search("#") + 1);
                        }
                        response[index].file = {
                            url: myDomain + element.ATTACHED_OBJECTS[property].VIEW_URL,
                            name: newName
                        };
                    }
                } else {
                    response[index].file = false;
                }
                if (element.AUTHOR_ID != "5") {
                    response[index]["authorName"] = element.AUTHOR_NAME;
                    response[index]["external"] = false;
                } else {
                    //get author comment from POST_MESSAGE
                    const regex = /^\(([^)]+)\):/;
                    const authorName = element.POST_MESSAGE.match(regex);
                    if (authorName) {
                        response[index]["authorName"] = authorName[1];
                        response[index].POST_MESSAGE = element.POST_MESSAGE.replace(regex, '').trim();
                        response[index]["external"] = true;
                    } else {
                        response[index]["authorName"] = "Sistema";
                        response[index]["external"] = false;
                    }
                }
            }
            return response;
        } else {
            return [];
        }
    } catch (err) {
        console.error(err);
    }
};

export const getTicketFiles = async (email, files) => {
    console.log("getTicketFiles email ", email);
    console.log("getTicketFiles files ", files);
    try {
        const response = await API.get(myAPI, `/files/${email}/${files}`);
        console.log("getTicketFiles API.get response ", response);

        if (response !== "No params" && response.ID) {
            if (response.NAME.search("#") > -1) {
                const newName = response.NAME.slice(response.NAME.search("#") + 1);
                response.NAME = newName;
            }
            return response;
        } else {
            return [];
        }
    } catch (err) {
        console.error(err);
    }
};

export const getTicketHistory = async (email, ticketId) => {
    console.log("getTicketHistory ticketId ", ticketId);
    try {
        const response = await API.get(myAPI, `/history/${email}/${ticketId}`);
        console.log("getTicketHistory API.get response ", response);

        if (response !== "No params" && response.length > 0) {
            return response;
        } else {
            return [];
        }
    } catch (err) {
        console.error(err);
    }
};

export const addTicketComment = async (email, params) => {
    console.log("addTicketComment params ", params);

    if (params.files !== null) {
        const id = uuid();
        const fileName = params.files.name;
        const fileKey = `${id}#${fileName}`;
        console.log("addTicketComment fileKey ", fileKey);

        const { key } = await Storage.put(fileKey, params.files, {
            contentType: params.files.type
        });
        console.log("addTicketComment key ", key);
        params.files = key;
    }

    try {
        const myInit = {
            body: params,
            headers: {}
        };
        const response = await API.post(myAPI, `/addcomment/${email}`, myInit);
        console.log("addTicketComment API.get response ", response);

        if (response !== "No params") {
            return response;
        } else {
            return null
        }
    } catch (err) {
        console.error(err);
    }
};
