import React from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { createTicket } from './queries';

import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';

function ModalTicketCreate({ user, userInfo, showCreate, handleCloseCreate, loadTasks }) {
  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [contracts, setContracts] = React.useState([]);
  const [contract, setContract] = React.useState("");
  const [files, setFiles] = React.useState(null);

  const handleFileChange = (e) => {
    setFiles(e.target.files[0]);
  };

  const clearFile = () => {
    setFiles(null);
  }

  const createNewTicket = async () => {
    console.log("createNewTicket");

    if (title !== "" && description !== "" && contract !== "") {

      const params = {
        title: title,
        description: description,
        contract: contract,
        files: files
      }

      const response = await createTicket(user.attributes.email, params);
      if (!!response) {
        setTitle("");
        setDescription("");
        setContract("");
        setFiles(null);
        //todo show create result
        handleCloseCreate();
        loadTasks();
      } else {
        //todo show create result
        handleCloseCreate();
      }
    }
  };

  function ContractsList() {
    console.log("ContractsList contracts", contracts);

    return <Select labelId="select-contract" value={contract} required onChange={(e) => setContract(e.target.value)}>
      {contracts.map((option) => (
        <MenuItem key={option.id} value={option.id}>{option.title}</MenuItem>
      ))}</Select >;
  }


  React.useEffect(() => {
    if (!!userInfo && !!userInfo.CONTRACTS) {
      console.log("useEffect userInfo", userInfo);
      setContracts(userInfo.CONTRACTS)
    }
  }, [userInfo]);



  return (
    <Dialog open={showCreate} onClose={handleCloseCreate}>
      <DialogTitle>Novo Ticket</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="title"
          label="Titulo"
          fullWidth
          variant="filled"
          value={title}
          required
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          margin="dense"
          id="description"
          label="Descrição"
          fullWidth
          variant="filled"
          multiline
          maxRows={6}
          rows={4}
          value={description}
          required
          onChange={(e) => setDescription(e.target.value)}
        />
        <FormControl variant="filled" sx={{ width: "100%", minWidth: 120 }}>
          <InputLabel id="select-contract">Contrato</InputLabel>
          <ContractsList />
        </FormControl>
        <Button component="label" color="secondary" variant="contained" sx={{ margin: "1em" }}>Anexo
          <input type="file" name="file" max="2097152" onChange={handleFileChange} hidden />
          <AttachFileIcon />
        </Button>{
          !!files ? <>{files.name}
            <Button size="small" color="error" onClick={clearFile}>X</Button>
          </> : <></>}
      </DialogContent>
      <DialogActions>
        <Button color="error" variant="contained" onClick={handleCloseCreate}>Cancelar</Button>
        <Button color="success" variant="contained" onClick={createNewTicket}>Salvar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(ModalTicketCreate);