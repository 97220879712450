import React from 'react';

function StartPage({ handleShowTaskList, handleShowCreate }) {

    return (
        <div className="body d-flex h-100 text-left text-bg-dark backInteratell">
            <div className="imagem_people"><img src="./people.png" /></div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <img src="./logotipo.png" className="logo_intetatell" />
                                    <p className="titulo_interatell">Bem-vindo ao<br />Portal do Cliente</p>
                                </div>
                                <div className="col-md-6">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row back_barra">
                    <div className="col-md-12">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4 mt-5">
                                    <h2>Um espaço pensado em você</h2>
                                    <p>Aqui você pode abrir um novo chamado ou acompanhar as solcitações já realizadas. Terá todo o suporte necessário para que a sua operação desempenhe um trabalho contínuo.</p>
                                </div>
                                <div className="col-md-3 text-center" style={{ marginTop: '5em' }}>
                                    <a href="#">
                                        <button className="btnNovoChamado align-bottom" onClick={handleShowCreate} >Abrir novo chamado</button>
                                    </a>
                                    <br />
                                    <a href="#">
                                        <button className="btnAcompanharSolicitacoes align-bottom" onClick={handleShowTaskList} >Acompanhar solicitações</button>
                                    </a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 float-right ">
                                    <a href="http://interatell.com.br/" target="_blank"><img src="./site.png" className="social align-middle" /></a>
                                    <a href="https://www.youtube.com/channel/UCE2WW9fERAlNx2pQr6JxZwQ" target="_blank"><img src="./youtube.png" className="social align-middle" /></a>
                                    <a href="https://www.linkedin.com/company/interatelltecnologia/mycompany/?viewAsMember=true" target="_blank"><img src="./linkedin.png" className="social align-middle" /></a>
                                    <a href="https://www.instagram.com/interatell/" target="_blank"><img src="./instagram.png" className="social align-middle" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="container">
                            <footer className="mt-left text-white-60 rodape">
                                <p>**Caso sua solicitação não tenha sido atendida, entre em contato com os nossos canais de atendimento via <a href="tel:+55 11 3303-3300">URA</a> ou <a href="https://api.whatsapp.com/send?phone=551133033300" target="_blank">WhatsApp</a>
                                </p></footer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(StartPage);
