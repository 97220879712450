import React from "react";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CommentsTable from "./CommentsTable/CommentsTable";
import { getTicketComments, addTicketComment, getTicketFiles } from './queries';

import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';

function ModalTicketDetails({ user, showDetails, handleCloseDetails, currentTicket }) {
    const [ticketComments, setTicketComments] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [comment, setComment] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [ticketFiles, setTicketFiles] = React.useState(null);
    const [files, setFiles] = React.useState(null);

    const SUCCESS = "Comentário criado com sucesso";
    const FAIL = "Algo deu errado, o comentário não foi criado";
    const NO_COMMENT = "O campo Comentário deve ser prenchido";

    const handleFileChange = (e) => {
        console.log("handleFileChange e.target.files[0]", e.target.files[0]);
        setFiles(e.target.files[0]);
    };

    const clearFile = () => {
        setFiles(null);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setFiles(null);
        setOpen(false);
    };

    const loadTicketDetails = async () => {
        console.log("loadTicketDetails currentTicket ", currentTicket);
        if (!!currentTicket && !!currentTicket.params.row.id) {
            setLoading(true);
            const responseComments = await getTicketComments(user.attributes.email, currentTicket.params.row.id);
            setTicketComments(responseComments);
            if (!!currentTicket && !!currentTicket.params.row.ufTaskWebdavFiles && currentTicket.params.row.ufTaskWebdavFiles.length > 0) {
                console.log("loadFilesDetails responseFiles ok");
                const responseFiles = await getTicketFiles(user.attributes.email, currentTicket.params.row.ufTaskWebdavFiles[0]);
                console.log("loadFilesDetails responseFiles ", responseFiles);
                if (!!responseFiles) {
                    setTicketFiles(responseFiles);
                } else {
                    setTicketFiles(null);
                }
            } else {
                setTicketFiles(null);
            }
            setLoading(false);
        }
    }

    React.useEffect(() => {
        const load = async () => {
            loadTicketDetails();
        };
        load();
    }, [currentTicket, user.attributes.email]);

    const sendComment = async () => {
        console.log("sendComment currentTicket ", currentTicket);
        if (!!currentTicket && !!currentTicket.params.row.id && comment !== "") {
            const params = {
                ticketid: currentTicket.params.row.id,
                comment: comment,
                files: files
            }

            const response = await addTicketComment(user.attributes.email, params);
            if (!!response) {
                setComment("");
                setMessage(SUCCESS);
                setOpen(true);
                loadTicketDetails();
            } else {
                setMessage(FAIL);
                setOpen(true);
            }
        }
        if (comment === "") {
            setMessage(NO_COMMENT);
            setOpen(true);
        }
    };

    return (
        currentTicket ?
            <Dialog
                fullWidth={true}
                maxWidth={"lg"}
                open={showDetails}
                onClose={handleCloseDetails}>
                <DialogTitle>Detalhes do Ticket</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3} sx={{ flexDirection: 'row' }} >
                        <Grid item xs={6}>
                            <TextField
                                margin="dense"
                                disabled={true}
                                id="description"
                                label="ID"
                                fullWidth
                                variant="outlined"
                                value={currentTicket.params.row.id}
                            />
                            <TextField
                                margin="dense"
                                disabled={true}
                                id="title"
                                label="Titulo"
                                fullWidth
                                variant="outlined"
                                value={currentTicket.params.row.title}
                            />
                            <TextField
                                margin="dense"
                                disabled={true}
                                id="description"
                                label="Descrição"
                                fullWidth
                                variant="outlined"
                                multiline
                                maxRows={4}
                                value={currentTicket.params.row.description}
                            />
                            <TextField
                                margin="dense"
                                disabled={true}
                                id="contractName"
                                label="Contrato"
                                fullWidth
                                variant="outlined"
                                multiline
                                maxRows={4}
                                value={currentTicket.params.row.contractName}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                margin="dense"
                                disabled={true}
                                id="status"
                                label="Status"
                                fullWidth
                                variant="outlined"
                                value={currentTicket.params.row.statusName}
                            />
                            <TextField
                                margin="dense"
                                disabled={true}
                                id="responsibleName"
                                label="Responsável"
                                fullWidth
                                variant="outlined"
                                value={currentTicket.params.row.responsibleName}
                            />
                            <TextField
                                margin="dense"
                                disabled={true}
                                id="createdDate"
                                label="Data criação"
                                fullWidth
                                variant="outlined"
                                value={new Date(currentTicket.params.row.createdDate).toLocaleString("pt-BR")}
                            />
                            <TextField
                                margin="dense"
                                disabled={true}
                                id="closedDate"
                                label="Data fechamento"
                                fullWidth
                                variant="outlined"
                                multiline
                                maxRows={4}
                                value={new Date(currentTicket.params.row.closedDate).toLocaleString("pt-BR")}
                            />
                            {ticketFiles ? <>Anexo:<br></br><Link href={ticketFiles.DOWNLOAD_URL} color="inherit" target="_blank">
                                {ticketFiles.NAME}
                            </Link></> : <>Anexo:<br></br><p>Sem arquivos</p></>}
                        </Grid>
                    </Grid>
                    <Box sx={{ width: '100%' }}>
                        <CommentsTable {...{ ticketComments, loading }} />
                    </Box>
                    <TextField
                        margin="dense"
                        id="comment"
                        label="Inserir comentário"
                        fullWidth
                        variant="filled"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    />
                    <Button color="success" variant="contained" sx={{ margin: "1em" }} onClick={sendComment}>Enviar</Button>
                    <Button component="label" color="secondary" variant="contained" sx={{ margin: "1em" }}>Anexo
                        <input type="file" name="file" max="2097152" onChange={handleFileChange} hidden />
                        <AttachFileIcon />
                    </Button>{
                        !!files ? <>{files.name}
                            <Button size="small" color="error" onClick={clearFile}>X</Button>
                        </> : <></>}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={handleCloseDetails}>Fechar</Button>
                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                        <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity={message === SUCCESS ? "success" : "error"} sx={{ width: '100%' }}>
                            {message}
                        </MuiAlert>
                    </Snackbar>
                </DialogActions>
            </Dialog > : <></>
    );
}

export default React.memo(ModalTicketDetails);