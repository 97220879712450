import AppBar from '@mui/material/AppBar';
import { Image } from "@aws-amplify/ui-react";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LogoutIcon from '@mui/icons-material/Logout';
//import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
//import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export function Header({ signOut, handleShowCreate }) {

    const openInNewTab = url => {
        window.open(url, '_blank');
    };

    return (
        <AppBar position="absolute" sx={{ backgroundColor: '#032756' }}>
            <Toolbar>
                <Image
                    alt="logo"
                    src="./logo_interatell.png"
                    objectPosition="50% 50%"
                    objectFit="initial"
                    width="10%"
                    onClick={() => openInNewTab("https://www.interatell.com.br/")}
                />
                <Typography
                    component="h1"
                    variant="h4"
                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1, textAlign: 'center' }}
                >Portal do Cliente
                </Typography>
                <Box sx={{ flexGrow: 1 }} >
                    <Button
                        onClick={handleShowCreate}
                        sx={{ backgroundColor: '#5ABF2E' }}
                        variant="contained"
                        startIcon={<AddCircleOutlineIcon />}>
                        Criar Ticket
                    </Button>
                </Box>
                {/*<IconButton size="large" aria-label="show 4 chat" color="inherit">
                    <QuestionAnswerIcon />
                </IconButton>
                <IconButton size="large" aria-label="show 4 whatsapp" color="inherit" onClick={() => openInNewTab("https://api.whatsapp.com/send/")}>
                    <WhatsAppIcon />
                </IconButton>*/}
                <IconButton size="large" aria-label="show 4 help" color="inherit" onClick={() => openInNewTab("https://www.interatell.com.br/")}>
                    <HelpOutlineIcon />
                </IconButton>
                <IconButton size="large" aria-label="show 4 logout" color="inherit" onClick={signOut}>
                    <LogoutIcon />
                </IconButton>
            </Toolbar>
        </AppBar >
    );
}
